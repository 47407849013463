import React from 'react'

type Icon = {
  color?: string
  className?: string
  width?: number
  height?: number
  strokeWidth?: number
}

export const ArrowRightUpIcon = ({
  color = 'black',
  className,
  width,
  height,
}: Icon) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 187.01 177.55"
    width={width}
    height={height}
    className={className}
  >
    <path
      fill={color}
      d="M23.09 31.46h114.32L17.22 151.65l13.41 13.42L150.82 44.88v114.31h18.98V12.48H23.09v18.98z"
    />
  </svg>
)

export const ArrowRightIcon = ({
  color = 'black',
  className,
  width,
  height,
}: Icon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 257.21 233.09"
    width={width}
    height={height}
    className={className}
  >
    <path
      fill={color}
      d="M114.6 26.23l80.83 80.83H25.46v18.97h169.97l-80.83 80.83 13.42 13.42 103.73-103.74L128.02 12.81z"
    />
  </svg>
)

export const DownloadIcon = ({
  color = 'black',
  className,
  width,
  height,
}: Icon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 187.01 233.09"
    width={width}
    height={height}
    className={className}
  >
    <path
      fill={color}
      d="M178.48 96.5l-12.84-12.84-63.05 63.05V12.48H84.43v134.23L21.37 83.66 8.53 96.5l84.98 84.98z"
    />
    <path
      fill={color}
      d="M162.98 158.15v36.71H24.03v-36.71H5.87v54.87h175.27v-54.87z"
    />
  </svg>
)

export const FileIcon = ({
  color = 'black',
  className,
  width,
  height,
}: Icon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 23"
    fill="none"
    width={width}
    height={height}
    className={className}
  >
    <path
      d="M7.03971 0L0 7.65269V23H17V0H7.03971ZM6.57131 3.47441V7.04766H3.28428L6.57131 3.47441ZM15.0713 20.9034H1.92869V9.14429H8.5V2.09663H15.0713V20.9034Z"
      fill={color}
    />
  </svg>
)

export const PlayIcon = ({
  color = '#ffffff',
  className,
  width = 474,
  height = 439,
  strokeWidth = 3,
}: Icon) => (
  <svg
    width={width}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 474 439"
  >
    <path
      d="M88.94 414.67V39.95l324.52 187.36L88.94 414.67zm18-343.55v312.37l270.52-156.18L106.94 71.12z"
      fill={color}
      strokeWidth={strokeWidth}
    />
  </svg>
)

export const GlobeIcon = ({
  color = '#ffffff',
  className,
  width = 20,
  height = 21,
  strokeWidth = 2,
}: Icon) => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 27"
  >
    <circle
      cx="13"
      cy="13.622"
      r="12"
      stroke={color}
      strokeWidth={strokeWidth}
    />
    <path
      d="M18.5 13.622c0 3.299-1.5 6.348-3.073 8.624A24.337 24.337 0 0113 25.22c-.087-.091-.182-.19-.282-.3a24.337 24.337 0 01-2.145-2.674C9 19.97 7.5 16.921 7.5 13.622c0-3.299 1.5-6.347 3.073-8.624A24.342 24.342 0 0113 2.024a24.342 24.342 0 012.427 2.974C17 7.275 18.5 10.323 18.5 13.622zM.729 13.554H25.38"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
)

export const MapIcon = ({
  color = '#ffffff',
  className,
  width = 20,
  height = 21,
}: Icon) => (
  <svg
    width={width}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z" />
  </svg>
)
