import React from 'react'
import { Box, Flex } from 'reflexbox'
import isNode from 'is-node'

import { ArrowRightUpIcon } from 'src/components/UI/Elements/Icon'
import Symbol from 'src/components/UI/Elements/Symbol'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Markdown from 'src/components/Markdown/Markdown'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import * as styles from './Footer.module.scss'

const Footer = (props: any) => {
  const { blok, header } = props
  const { social_links, address_field_1, site_info }: any = blok
    ? blok.content
      ? blok.content
      : blok
    : {}
  const { links }: any = header ? header.content : {}

  const Links = ({ links }: any) => {
    return (
      <React.Fragment>
        {links?.length > 0 && (
          <Flex flexDirection="column" alignItems="flex-start">
            {links.map((link: any) => (
              <LinkComponent key={link.slug} to={link.slug}>
                {link.title}
              </LinkComponent>
            ))}
          </Flex>
        )}
      </React.Fragment>
    )
  }

  const SocialLinks = ({ social_links }: any) => {
    return (
      <React.Fragment>
        {social_links &&
          social_links.length > 0 &&
          social_links.map((link: any) => (
            <Flex
              className={styles.socialLink}
              key={link.slug}
              justifyContent="space-between"
              alignItems="center"
            >
              <LinkComponent to={link.slug}>{link.title}</LinkComponent>
              <ArrowRightUpIcon color="white" width={12} height={12} />
            </Flex>
          ))}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Container
        marginTop="auto"
        paddingTop={[16, null, null, 18]}
        paddingBottom={[10, null, null, 7]}
        className={styles.wrapper}
        as="footer"
        width={1}
      >
        <Row flexDirection="row">
          <Column width={2 / 12} className="hideMobile">
            <button
              className={styles.button}
              onClick={() => {
                if (!isNode && window) {
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }
              }}
            >
              <Symbol className={styles.symbol} />
            </button>
          </Column>
          <Column width={[1, null, null, 10 / 12]}>
            <Row>
              <Column
                className="hideDesktop"
                width={3 / 4}
                marginLeft={(1 / 4) * 100 + '%'}
              >
                {links?.length > 0 && (
                  <Flex
                    alignItems="flex-start"
                    flexDirection="column"
                    marginBottom={[11]}
                  >
                    <Links links={links} />
                  </Flex>
                )}
                {social_links?.length > 0 && (
                  <Flex
                    alignItems="flex-start"
                    flexDirection="column"
                    marginBottom={[23]}
                  >
                    <SocialLinks social_links={social_links} />
                  </Flex>
                )}
              </Column>
            </Row>

            <Row width={1} flexDirection="row">
              <Column width={1 / 4} className="hideDesktop">
                <Box flexShrink={0} flexGrow={0} width={1 / 4}>
                  <button
                    className={styles.button}
                    onClick={() => {
                      if (!isNode && window) {
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                      }
                    }}
                  >
                    <Symbol className={styles.symbol} />
                  </button>
                </Box>
              </Column>
              <Column width={[3 / 4, null, null, 1]}>
                <Row width={1}>
                  <Column
                    className="hideMobile"
                    width={[1, null, null, 2 / 10]}
                  >
                    <Links links={links} />
                  </Column>
                  <Column
                    className="hideMobile"
                    alignItems="flex-start"
                    width={[1, null, null, 2 / 10]}
                    marginBottom={[6, null, null, 0]}
                  >
                    <SocialLinks social_links={social_links} />
                  </Column>
                  <Column width={[1, null, null, 2 / 10]} />
                  <Column
                    className={styles.address}
                    width={[1, null, null, 2 / 10]}
                    marginBottom={[6, null, null, 0]}
                  >
                    <Markdown source={address_field_1} />
                  </Column>
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
        {site_info && (
          <Row width={1}>
            <Column
              width={[3 / 4, null, null, 4 / 12]}
              marginLeft={[
                (1 / 4) * 100 + '%',
                null,
                null,
                (4 / 12) * 100 + '%',
              ]}
            >
              <Box marginTop={[10, null, null, 12]} className={styles.info}>
                <Markdown source={site_info} />
              </Box>
            </Column>
          </Row>
        )}
      </Container>
    </React.Fragment>
  )
}

export default Footer
