import React from 'react'
import Helmet from 'react-helmet'

import { defaultLocale, locales } from 'src/config'

import { Props } from './types'

const siteUrl = process.env.GATSBY_SITE_URL

const SEO = (props: Props) => {
  const {
    metaDescription,
    title,
    type,
    locale,
    bodyClass,

    path,
  } = props

  let { image } = props

  if (image) {
    if (image.startsWith('//')) {
      image = 'https:' + image
    }
  }
  const helmetProps = {
    bodyAttributes: {
      class: bodyClass || '',
    },
  }

  const pageUrl = siteUrl && path ? `${siteUrl}${path}` : null
  const originalPath =
    path && locale === defaultLocale
      ? path
      : path
      ? path.replace(/^\/[a-z]{2}/, '')
      : null
  return (
    <Helmet defer={false} {...helmetProps}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      {title && <title>{title}</title>}

      {pageUrl && <link rel="canonical" href={pageUrl} />}
      {locale && <meta property="og:locale" content={locale} />}
      {siteUrl && locales[locale] && originalPath && (
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${siteUrl}${locales[locale].path}${originalPath}`}
        />
      )}
      {Object.keys(locales).map((localeKey: any) => {
        return siteUrl && locales[localeKey] && originalPath ? (
          <link
            key={localeKey}
            rel="alternate"
            hrefLang={localeKey}
            href={`${siteUrl}${locales[localeKey].path}${originalPath}`}
          />
        ) : null
      })}

      {title && <meta property="og:title" content={title} />}
      {title && <meta property="twitter:title" content={title} />}
      {title && <meta property="og:image:alt" content={title} />}
      {title && <meta property="twitter:image:alt" content={title} />}

      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaDescription && (
        <meta name="og:description" content={metaDescription} />
      )}

      {image && <meta property="og:image" content={image} />}
      {image && <meta property="twitter:image:src" content={image} />}

      {type && <meta property="og:type" content={type} />}
      {type && <meta property="twitter:type" content={type} />}

      {pageUrl && <meta property="og:url" content={pageUrl} />}
      {pageUrl && <meta property="twitter:url" content={pageUrl} />}
    </Helmet>
  )
}

export default SEO
