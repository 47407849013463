const parseCisionData = (post) => {
  let release = {}
  if (post) {
    release = {
      title: post.Title ? post.Title : '',
      url: post.CisionWireUrl ? post.CisionWireUrl : '',
      publishDate: post.PublishDate ? post.PublishDate : '',
      // required fields
      id: post.Id ? `cision-${post.Id}` : '',
      parent: null,
      children: [],
      internal: {
        type: `cisionPost`,
      },
      // custom data
      date: post.PublishDate ? post.PublishDate : '',
      keyWords: post.Keywords && post.Keywords.length > 1 ? post.Keywords : [],
      isRegulatory: post.IsRegulatory ? post.IsRegulatory : false,
    }
  }

  return release
}

module.exports = parseCisionData
