export const translations = {
  sv: {
    translations: {
      showAll: 'Visa alla',
      showMore: 'Visa mer',
      showLess: 'Visa mindre',
      showDocument: 'Visa dokument',
      readMore: 'Läs mer',
      readMoreFund: 'Läs mer om fonden',
      regulatoryPressRelease: 'Regulatoriskt pressmeddelande',
      noneRegulatoryPressRelease: 'Icke regulatoriskt pressmeddelande',
      finishedFunds: 'Avslutade fonder',
      name: 'Namn',
      company: 'Företag',
      eMail: 'E-post',
      theGeographicLocations: "Våra fastigheters geografiska platser",
      team: 'Medarbetare',
      imagesOf: 'Bilder från',
      logotypes: "Logotyper",
      pressImages: 'Pressbilder',
      cookieSettings: 'Uppdatera cookie-inställningar'
    },
  },
  en: {
    translations: {
      showAll: 'Show all',
      showMore: 'Show more',
      showLess: 'Show less',
      showDocument: 'Show document',
      readMore: 'Read more',
      readMoreFund: 'Read more about the fund',
      regulatoryPressRelease: 'Regulatory news',
      noneRegulatoryPressRelease: 'Non-regulatory news',
      finishedFunds: 'Closed funds',
      name: 'Name',
      company: 'Company',
      eMail: 'E-mail',
      theGeographicLocations: "The geographic locations of our properties",
      team: 'Team',
      imagesOf: 'Images of',
      logotypes: "Logotypes",
      pressImages: 'Press images',
      cookieSettings: 'Update cookie settings'
    },
  },
}
