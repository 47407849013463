import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'

import * as styles from './Markdown.module.scss'
import LinkComponent from '../LinkComponent/LinkComponent'

// import { getIntlUrl } from 'src/utils/intl'

type Props = {
  className?: any
  noParagraphs?: boolean
  source: any
  escapeHtml?: boolean
  readMore?: boolean
}

type MarkdownProps = {
  className: any
  disallowedTypes?: any
  noParagraphs?: boolean
  plugins: any
  source: any
  transformLinkUri: any
  linkTarget: any
  unwrapDisallowed?: boolean
  escapeHtml?: boolean
}

const linkTarget = (url = '') => {
  let returnTarget
  const localUrl = url.split('?').shift()
  const isMail = url && url.split(':')[0] === 'mailto'
  const isPhone = url && url.split(':')[0] === 'tel'

  if ((localUrl && localUrl.substr(-4, 4) === '.pdf') || isMail || isPhone) {
    returnTarget = '_blank'
  }

  return returnTarget
}

const LinkRenderer = (props: any) => {
  const { children, node } = props
  let { url } = node
  const target = linkTarget(url)
  if (url.startsWith('www')) {
    url = `https://${url}`
  }
  if (url.startsWith('/')) {
    return <LinkComponent to={url}>{children}</LinkComponent>
  }
  return (
    <a
      href={url}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : ''}
    >
      {children}
    </a>
  )
}
const Markdown = ({
  className = '',
  noParagraphs = false,
  source,
  escapeHtml = false,
  readMore = false,
}: Props): JSX.Element => {
  // const [showAll, setShowAll] = React.useState(readMore ? false : true)
  const transformLinkUri = (uri: string) => {
    // let localUri = uriTransformer(uri)
    // localUri = getIntlUrl(localUri, activeLocale)
    // return localUri
  }

  const plugins = [remarkBreaks]

  const markdownProps: MarkdownProps = {
    className,
    plugins,
    source,
    transformLinkUri,
    escapeHtml,
    // linkTarget,
  }

  if (noParagraphs) {
    markdownProps.disallowedTypes = ['paragraph']
    markdownProps.unwrapDisallowed = true
  }

  return (
    <div className={styles.markdown}>
      <ReactMarkdown renderers={{ link: LinkRenderer }} {...markdownProps} />
      {/* {!showAll && (
        <Flex
          flexDirection="column"
          as="button"
          onClick={() => {
            setShowAll(true)
          }}
          className={styles.readMoreButton}
        >
          <span>Läs mer</span>
          <svg
            viewBox="0 0 72 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L36 9.13525L71 1" stroke="#D4CC33" />
          </svg>
        </Flex>
      )} */}
    </div>
  )
}

export default Markdown
