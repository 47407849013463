import { observable, action, computed } from 'mobx'
import axios from 'axios'

import parseCisionData from 'src/helpers/parsers'

export default class Press {
  @observable page = 1
  @observable posts: any[] = []
  @observable allFetched = false
  @observable keywordPosts: any = {
    'Estea AB': {
      fetched: false,
      posts: [],
    },
    'Estea Logistic Properties 5 AB': {
      fetched: false,
      posts: [],
    },
    'Estea Omsorgsfastigheter AB publ': {
      fetched: false,
      posts: [],
    },
    'Estea Sverigefastigheter 2 AB': {
      fetched: false,
      posts: [],
    },
    'Estea Sverigefastigheter 3 AB': {
      fetched: false,
      posts: [],
    },
  }

  @action.bound
  async fetchNextPosts() {
    const key = '95859645F7DB40F5909ADD01207B7288'
    const pageSize = 20
    const url = `https://publish.ne.cision.com/papi/NewsFeed/${key}?format=json&detailLevel=detail&pageSize=${pageSize}&pageIndex=${this.page}`
    try {
      const response = await axios({
        url: url,
        method: 'GET',
      })
      if (response && response.data && response.data.Releases) {
        this.updatePosts(
          response.data.Releases,
          response.data.TotalFoundReleases
        )
      }
    } catch (e) {
      console.warn(e)
    }
  }

  @action.bound
  async fetchByKeyword(keyword: string) {
    const key = '95859645F7DB40F5909ADD01207B7288'
    const pageSize = 50
    const url = `https://publish.ne.cision.com/papi/NewsFeed/${key}?format=json&detailLevel=detail&pageSize=${pageSize}&Tags=${keyword}`
    try {
      const response = await axios({
        url: url,
        method: 'GET',
      })
      if (response && response.data && response.data.Releases) {
        this.updateKeywords(response.data.Releases, keyword)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  @action.bound
  updateKeywords(entries: any[], keyword: string) {
    const data = this.keywordPosts
    if (data[keyword]) {
      data[keyword].fetched = true
      data[keyword].posts = entries
      this.keywordPosts = data
    }
  }

  @action.bound
  updatePosts(entries: any[], totalPosts: number) {
    this.posts = [...this.posts, ...entries]
    this.page = this.page + 1
    if (this.posts.length >= totalPosts) {
      this.allFetched = true
    }
  }

  @computed
  get entries(): any[] {
    if (!this.posts) {
      return []
    }

    return this.posts.map((post: any) => {
      return parseCisionData(post)
    })
  }

  entriesByKey(keyword: string): any[] {
    const posts = this.keywordPosts[keyword]?.posts || []
    return posts?.length > 0
      ? posts.map((post: any) => {
          return parseCisionData(post)
        })
      : null
  }
}
