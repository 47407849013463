import React from 'react'
import { Flex } from 'reflexbox'

const Column = (props: any) => <Flex {...props} />

Column.defaultProps = {
  paddingX: ['10px', null, null, 5],
  flexDirection: 'column',
}

export default Column
