import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { translations } from './translations'

i18n.use(initReactI18next).init({
  // we init with resources
  resources: translations,
  fallbackLng: 'sv',
  debug: true,
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  // keySeparator: true,
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
  react: {
    useSuspense: true,
  },
})

export default i18n
