import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import SbEditable from 'storyblok-react'
import { reaction } from 'mobx'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import isNode from 'is-node'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import { defaultLocale } from 'src/config'
import Header from 'src/storyblok/GlobalModules/Header/Header'
import Footer from 'src/storyblok/GlobalModules/Footer/Footer'
import CookieConsent from 'src/storyblok/GlobalModules/CookieConsent/CookieConsent'
import SEO from 'src/components/SEO/SEO'
import useStore from 'src/stores/useStore'
import { getSeo } from 'src/helpers/getSeo'

import { Props } from './types'

export default ({ children, pageContext }: Props) => {
  const { t, i18n } = useTranslation()

  const { ui } = useStore()

  const { globalData, locale, story }: any = pageContext ? pageContext : {}

  let path = ''

  if (story && story.full_slug) {
    if (story.full_slug === 'home' || story.full_slug === 'en/home') {
      if (story.full_slug === 'home') {
        path = story.full_slug.replace('home', '/')
      } else {
        path = story.full_slug.replace('home', '')
      }
    } else {
      path = `/${story.full_slug}`
    }
  }

  const { header, footer, seo, disclaimer }: any = globalData ? globalData : {}
  const seo_data: any = seo ? seo.content : {}
  const { seo_description, seo_title, seo_image } = getSeo(seo_data)

  useEffect(() => {
    const disposerMenu = reaction(
      () => ui.activeMenu,
      () => {
        const body = document.querySelector('body')
        if (ui.activeMenu) {
          disableBodyScroll(body)
        } else {
          enableBodyScroll(body)
          clearAllBodyScrollLocks()
        }
      }
    )

    if (disclaimer?.content) {
      const { title, content, button_text } = disclaimer.content
      if (title && content && button_text) {
        ui?.setDisclaimerContent({
          title,
          content,
          buttonText: button_text,
        })
      }
    }

    return () => {
      disposerMenu()
    }
  }, [i18n, ui])

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [path])

  return (
    <React.Fragment>
      {(seo_description || seo_title || seo_image) && (
        <React.Fragment>
          <Helmet
            titleTemplate={`%s — ${seo_title}`}
            defaultTitle={seo_title}
            htmlAttributes={{
              lang: locale ? locale : defaultLocale,
            }}
          />
          <SEO
            metaDescription={seo_description}
            image={seo_image}
            locale={locale}
            path={path}
          />
        </React.Fragment>
      )}
      <Flex flexDirection="column" minHeight="100vh">
        {header && (
          <SbEditable content={header.content}>
            <Header locale={locale} blok={header} path={path} />
          </SbEditable>
        )}
        {children}
        {footer && (
          <SbEditable content={footer.content}>
            <Footer blok={footer} header={header} />
          </SbEditable>
        )}
      </Flex>
    </React.Fragment>
  )
}
