import React from 'react'
import { Box, Flex } from 'reflexbox'
import isNode from 'is-node'

import { locales } from 'src/config'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import MobileNav from 'src/components/MobileNav/MobileNav'
import Container from 'src/components/UI/Grid/Container'
import stores from 'src/stores/decorator'

import { Props } from './types'
import * as styles from './Header.module.scss'


@stores
class Header extends React.Component<Props> {
  previousScroll = 0
  headerElement = null

  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    if (!isNode) {
      document.addEventListener('scroll', this.handleScroll)
    }
  }

  componentDidUpdate() {
    if (!isNode) {
      this.handleScroll()
    }
  }

  componentWillUnmount() {
    if (!isNode) {
      document.removeEventListener('scroll', this.handleScroll)
    }
  }

  handleScroll = () => {
    if (!isNode) {
      const offset = 70
      const currentScroll = window.scrollY
      let direction = 0

      if (!this.headerElement) {
        this.headerElement = document.getElementById('header')
      } else {
        if (currentScroll > this.previousScroll) {
          //scrolled up
          direction = 2
        } else if (currentScroll < this.previousScroll) {
          //scrolled down
          direction = 1
        }
        if (window) {
          if (direction === 1) {
            this.headerElement.classList.add('up')
            this.headerElement.classList.remove('down')
          }
          if (direction === 2) {
            this.headerElement.classList.add('down')
            this.headerElement.classList.remove('up')
          }
          if (window.scrollY > offset) {
            if (!this.headerElement.classList.contains('active')) {
              this.headerElement.classList.add('active')
            }
          } else {
            if (this.headerElement.classList.contains('active')) {
              this.headerElement.classList.remove('active')
            }
          }
        }
        this.previousScroll = currentScroll
      }
    }
  }

  render() {
    const { ui, blok, preview, path, locale } = this.props

    let svPath = ''
    let enPath = ''

    if (path?.startsWith('/en')) {
      svPath = path.replace('/en/', '/')
      enPath = path
    } else {
      svPath = path
      enPath = '/en' + path
    }

    const { links }: any = blok && blok.content ? blok.content : {}
    const { activeMenu, setActiveMenu } = ui || {}

    return (
      <React.Fragment>
        <header
          className={[
            styles.wrapper,
            activeMenu === 'nav' ? styles.active : '',
          ].join(' ')}
        >
          <Container
            className={[styles.container, preview ? styles.preview : ''].join(
              ' '
            )}
            flexDirection="row"
            alignItems={'center'}
            justifyContent="space-between"
            id="header"
          >
            <LinkComponent to="/" ariaLabel="Home">
              <svg
                className={styles.logotype}
                viewBox="0 0 122 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.3309 0C26.8205 0 27.0682 0.0974359 27.0682 0.785219V8.9641C27.0682 9.40543 26.7744 9.55445 26.4749 9.55445C26.1293 9.55445 25.7376 9.30799 25.7376 8.91825C25.7376 5.88054 23.6235 1.52459 18.6983 1.52459H16.4863C14.5681 1.52459 12.6499 1.77104 11.8607 2.64796V17.0455H16.6361C18.802 17.0455 21.4576 16.3119 22.0509 12.2941C22.1488 11.7038 22.4945 11.4115 22.7422 11.4115C22.9899 11.4115 23.1857 11.6579 23.1857 12.1451C23.1857 13.8072 22.8401 15.0854 22.8401 18.0199C22.8401 21.2983 23.1857 22.2326 23.1857 23.4075C23.1857 23.8947 22.9899 24.1412 22.7422 24.1412C22.4945 24.1412 22.1488 23.8489 22.0509 23.2585C21.4576 19.4872 18.9 18.702 16.6361 18.702H11.8607V23.8431C11.8607 27.3222 12.0105 31.3342 12.1545 32.7041C12.4022 34.8591 13.3354 35.9825 18.6523 35.9825C22.8343 35.9825 26.6765 32.6066 28.2491 27.3164C28.3989 26.7777 28.7388 26.5312 29.0383 26.5312C29.3839 26.5312 29.6316 26.7777 29.6316 27.1674C29.6316 28.0501 27.6616 35.6386 27.3678 36.5671C27.218 37.1059 26.7284 37.4498 26.1869 37.4498H0.737334C0.247698 37.4612 0 37.1632 0 36.8709C0 36.527 0.293782 36.1373 0.933189 36.1373C4.76963 36.1373 5.50696 34.9623 5.75466 32.0736C5.85259 30.7038 6.04844 27.2247 6.04844 20.4673V17.2347C6.04844 10.5288 5.85259 6.56259 5.75466 5.18703C5.51273 2.34992 4.87332 1.32398 1.18089 1.32398C0.587563 1.32398 0.293782 0.98009 0.293782 0.636199C0.293782 0.343891 0.54148 0 1.03112 0H26.3309Z"
                  fill="white"
                />
                <path
                  d="M50.1502 11.6063C50.1502 14.3002 48.0362 15.3261 45.4267 15.3261C45.4267 13.8588 45.2308 9.05581 40.9508 9.05581C38.4393 9.05581 36.9646 10.9644 36.9646 13.022C36.9646 15.6184 37.9957 16.7934 42.3276 19.4872C47.7885 22.8631 51.2332 24.8748 51.2332 29.9186C51.2332 35.6959 45.035 37.9484 39.7181 37.9484C34.4992 37.9484 30.8125 36.0857 30.8125 32.7098C30.8125 29.9186 33.2261 28.8926 36.1755 28.8926C35.9796 29.6778 35.8298 30.6063 35.8298 31.7813C35.8298 34.0338 36.619 36.4812 40.3575 36.4812C43.4566 36.4812 46.2619 34.9164 46.2619 31.4374C46.2619 29.1849 45.0292 27.2247 39.4704 24.0437C35.5821 21.8428 31.9876 19.7336 31.9876 14.9879C31.9876 9.40544 37.5464 7.59427 42.028 7.59427C45.323 7.58854 50.1502 8.27632 50.1502 11.6063Z"
                  fill="white"
                />
                <path
                  d="M61.417 8.4711C63.485 8.4711 66.3883 8.08135 68.0127 8.08135C68.848 8.08135 69.3434 8.62012 69.3434 9.15888C69.3434 9.69764 68.8537 10.2364 67.7189 10.2364H61.417V30.8985C61.417 33.8847 62.3502 35.0138 64.1705 35.0138C66.1406 35.0138 67.5173 33.4949 67.8629 31.3915C67.9609 30.8527 68.2547 30.5604 68.5024 30.5604C68.7961 30.5604 69.0957 30.9043 69.0957 31.4889C69.0957 35.8964 65.9966 37.9999 62.5979 37.9999C57.7765 37.9999 55.6105 35.5984 55.6105 31.1966C55.6105 26.2044 55.9562 14.9878 55.9562 10.288H53.5425C53.1508 10.288 52.9492 10.139 52.9492 9.84666C52.9492 9.60021 53.099 9.35948 53.4907 8.96401L59.7408 2.84274C60.1843 2.453 60.4781 2.20654 60.7719 2.20654C61.1636 2.20654 61.4113 2.49885 61.4113 3.18663V8.4711H61.417Z"
                  fill="white"
                />
                <path
                  d="M83.5665 37.9483C76.2335 37.9483 71.0664 33.2484 71.0664 23.6481C71.0664 14.5923 75.5423 7.58838 84.5976 7.58838C88.6818 7.58838 94.0447 9.78928 94.0447 17.1371C94.0447 18.9024 93.1576 19.5386 91.0435 19.5386H76.8211C76.8211 28.8409 80.214 34.1769 85.6287 34.1769C88.9756 34.1769 91.2855 33.151 93.1115 29.3796C93.2613 29.0873 93.4572 28.9899 93.653 28.9899C93.9468 28.9899 94.0447 29.2363 94.0447 29.5802C94.0447 32.6122 91.0954 37.9483 83.5665 37.9483ZM76.919 18.2662L85.9744 17.63C87.5988 17.5326 87.8926 16.8964 87.8926 15.8188C87.8926 11.3139 86.464 9.15882 83.6126 9.15882C79.4766 9.15882 77.5123 13.4632 76.919 18.2662Z"
                  fill="white"
                />
                <path
                  d="M111.81 19.1947C111.81 13.0734 110.577 9.06138 106.493 9.06138C103.342 9.06138 102.507 12.0017 102.507 14.3974C102.507 15.2285 102.605 16.0137 102.754 16.5983C102.115 16.7474 101.574 16.7932 101.084 16.7932C98.7221 16.7932 97.4894 15.3718 97.4894 13.3658C97.4894 9.88672 101.527 7.58838 107.726 7.58838C113.532 7.58838 117.323 9.69185 117.323 15.7157C117.323 17.481 117.029 22.0834 117.029 29.3796C117.029 33.0994 117.029 34.8131 118.999 34.8131C120.819 34.8131 120.871 32.4632 121.556 32.4632C121.902 32.4632 122 32.7096 122 33.2484C122 35.5525 120.473 37.9483 116.885 37.9483C113.884 37.9483 112.409 36.1371 111.769 34.5724C109.753 36.7274 106.85 37.9483 104.045 37.9483C99.6668 37.9483 96.418 35.5009 96.418 31.2882C96.418 23.9461 103.601 22.3299 109.557 22.3299C110.347 22.3299 111.084 22.3814 111.821 22.4273V19.1947H111.81ZM106.942 34.7157C108.665 34.7157 109.995 34.3718 111.568 33.2484C111.816 31.6321 111.816 26.4394 111.816 23.8487H110.733C105.761 23.8487 102.167 25.8089 102.167 30.3139C102.167 33.2484 104.039 34.7157 106.942 34.7157Z"
                  fill="white"
                />
              </svg>
            </LinkComponent>
            <Box
              aria-label="Menu Button"
              className={
                activeMenu === 'nav'
                  ? [styles.hamburger, styles.active, 'hideDesktop'].join(' ')
                  : [styles.hamburger, 'hideDesktop'].join(' ')
              }
              as="button"
              onClick={() => {
                if (activeMenu === 'nav') {
                  setActiveMenu('')
                } else {
                  setActiveMenu('nav')
                }
              }}
            >
              <div />
              <div />
            </Box>
            <Box className={[styles.navigation, 'hideMobile'].join(' ')}>
              <Flex
                className={styles.upperNavigation}
                justifyContent="flex-end"
                marginBottom={3}
              >
                <Flex
                  className={styles.language}
                  alignItems="center"
                  justifyContent="center"
                >
                  {Object.keys(locales).map((localeKey: string) => (
                    <LinkComponent
                      to={path}
                      lang={localeKey}
                      key={localeKey}
                      className={locale === localeKey ? styles.selected : ''}
                    >
                      <Box as="span">{locales[localeKey].title}</Box>
                    </LinkComponent>
                  ))}
                </Flex>
              </Flex>
              <Flex justifyContent="flex-end">
                {links &&
                  links.length > 0 &&
                  links.map((link: any) => (
                    <LinkComponent
                      to={link.slug}
                      className={styles.link}
                      key={link._uid}
                    >
                      {link.title}
                    </LinkComponent>
                  ))}
              </Flex>
            </Box>
          </Container>
        </header>
        <MobileNav path={path} locale={locale} links={links} />
      </React.Fragment>
    )
  }
}

export default Header
