const config = {
  home: { slug: 'home', component: 'page_start' },
  defaultLocale: 'sv',
  locales: {
    // Locales represent Storyblok languages.
    // Keys need to be set up in Storyblok with correct spelling.
    sv: { path: '', title: 'Svenska' },
    en: { path: '/en', title: 'English' },
  },
  resolveRelations: [
    'page_investment_landing.list_items',
    'page_investment_fund.real_estates_included',
    'page_investment_landing.closed_funds',
  ],
}

module.exports = config
