import React from 'react'
import { Flex } from 'reflexbox'

const Row = (props: any) => <Flex {...props} />

Row.defaultProps = {
  marginX: ['-10px', null, null, '-20px'],
  flexDirection: ['column', null, null, 'row'],
  flexWrap: 'wrap',
}

export default Row
