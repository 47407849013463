import { observable, action } from 'mobx'

export default class Ui {
  @observable disclaimerContent = { title: '', content: '', buttonText: '' }
  @observable windowHeight = 0
  @observable windowWidth = 0
  @observable mouseLeft = 0
  @observable mouseTop = 0
  @observable headerHeight = 0
  @observable activeMenu = ''
  @observable activeModal = ''
  @observable showPreviewbar = true
  @observable cursorContent = ''

  @action.bound
  setDisclaimerContent({
    title,
    content,
    buttonText,
  }: {
    title: any
    content: any
    buttonText: any
  }) {
    this.disclaimerContent = { title, content, buttonText }
  }

  @action.bound
  setWindowSize({ width, height }: { width: number; height: number }) {
    this.windowWidth = width
    this.windowHeight = height
  }

  @action.bound
  setMousePosition({ left, top }: { left: number; top: number }) {
    this.mouseLeft = left
    this.mouseTop = top
  }

  @action.bound
  setCursorContent(cursorContent: string) {
    if (cursorContent !== this.cursorContent) {
      this.cursorContent = cursorContent
    }
  }

  @action.bound
  setHeaderHeight(height: number) {
    this.headerHeight = height
  }

  @action.bound
  setActiveMenu(selected: string) {
    this.activeMenu = selected
  }

  @action.bound
  setActiveModal(selected: string) {
    this.activeModal = selected
  }

  @action.bound
  togglePreviewbar() {
    this.showPreviewbar = !this.showPreviewbar
  }
}
