import React from 'react'
import { Flex } from 'reflexbox'

const Container = (props: any) => <Flex {...props} />

Container.defaultProps = {
  paddingX: [7, null, null, 16],
  width: 1,
  flexDirection: 'column',
}

export default Container
