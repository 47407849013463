import React from 'react'
import { Box, Flex } from 'reflexbox'
import { use100vh } from 'react-div-100vh'

import { locales } from 'src/config'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import useStore from 'src/stores/useStore'
import Container from 'src/components/UI/Grid/Container'

import * as styles from './MobileNav.module.scss'
import { Props } from './types'

const MobileNav = ({ links, path, locale }: Props) => {
  const { ui } = useStore()
  const { activeMenu, setActiveMenu } = ui || {}
  const height = use100vh()
  return (
    <Container
      className={
        activeMenu === 'nav'
          ? [styles.wrapper, styles.active].join(' ')
          : styles.wrapper
      }
      height={height ? height : '100vh'}
      justifyContent="flex-end"
      paddingTop={16}
      paddingBottom={12}
    >
      <Flex flexDirection="column" width={1}>
        {links?.length > 0 &&
          links.map((link: any, index: number) => {
            return (
              <Box
                marginBottom={index + 1 === links.length ? 0 : 6}
                style={{ zIndex: 1 }}
                key={link._uid}
              >
                <LinkComponent
                  className={styles.link}
                  to={link.slug}
                  onClick={() => {
                    if (activeMenu === 'nav') {
                      setActiveMenu('')
                    } else {
                      setActiveMenu('nav')
                    }
                  }}
                >
                  {link.title}
                </LinkComponent>
              </Box>
            )
          })}
        <Box className={styles.language} marginTop={12}>
          {Object.keys(locales).map((localeKey: string) => (
            <Box marginBottom={4} key={localeKey}>
              <LinkComponent
                to={path}
                lang={localeKey}
                className={locale === localeKey ? styles.selected : ''}
                // onClick={() => {
                //   if (activeMenu === 'nav') {
                //     setActiveMenu('')
                //   } else {
                //     setActiveMenu('nav')
                //   }
                // }}
              >
                {locales[localeKey].title}
              </LinkComponent>
            </Box>
          ))}
        </Box>
      </Flex>
    </Container>
  )
}

export default MobileNav
