import * as React from 'react'
import { Link } from 'gatsby'

import { Props } from './types'
import { useTranslation } from 'react-i18next'

const LinkComponent = ({
  to,
  children,
  onClick,
  className,
  title,
  lang,
  onMouseEnter,
  onMouseLeave,
  ariaLabel,
}: Props): JSX.Element => {
  const { i18n } = useTranslation()

  if (!to) {
    return <React.Fragment>{children}</React.Fragment>
  }
  const isDownload = to.substr(0, 9) === 'data:text'
  const isExternal =
    to.substr(0, 4) === 'http' || to.substr(0, 7) === 'mailto:' || isDownload

  // Check for missing "/" in relative urls
  if (!isExternal && !to.startsWith('/')) {
    to = `/${to}`
  }

  const language = lang || (i18n && i18n.language)

  if (language === 'en' && !isExternal && !to.startsWith('/en')) {
    to = `/en${to}`
  }

  if (language === 'sv' && !isExternal && to.startsWith('/en')) {
    to = to.replace('/en/', '/')
  }

  return isExternal ? (
    <a
      aria-label={ariaLabel}
      className={className}
      href={to}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
      download={isDownload}
    >
      {children}
    </a>
  ) : (
    <Link
      aria-label={ariaLabel}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      title={title}
      to={to}
    >
      {children}
    </Link>
  )
}

export default LinkComponent
