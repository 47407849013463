/* eslint react/display-name: 0 */

import * as React from 'react'
import { observer } from 'mobx-react'

import { StoresContext } from 'src/components/Wrapper/Wrapper'

import { StoreTypes } from './types'

export default <P extends object>(Component: React.ComponentType<P>) => {
  const ObserverComponent: React.ComponentType<P> = observer(Component)
  const DecoratedComponent: any = class extends React.Component<
    P & { innerRef: typeof Component }
  > {
    WrappedComponent = Component
    render() {
      return (
        <StoresContext.Consumer>
          {(stores: StoreTypes) => (
            <ObserverComponent
              {...this.props}
              {...stores}
              ref={this.props.innerRef}
            />
          )}
        </StoresContext.Consumer>
      )
    }
  }
  return React.forwardRef((props, ref) => (
    <DecoratedComponent innerRef={ref} {...props} />
  )) as any
}
