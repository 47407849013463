// extracted by mini-css-extract-plugin
export var active = "Header-module--active--vgsTD";
export var container = "Header-module--container--14icn";
export var hamburger = "Header-module--hamburger--dEGnA";
export var language = "Header-module--language--PaoDO";
export var languagePicker = "Header-module--languagePicker--xWTVL";
export var link = "Header-module--link--+yMlA";
export var linkSmall = "Header-module--linkSmall--Esne2";
export var logotype = "Header-module--logotype--0XM45";
export var navigation = "Header-module--navigation--0KgIo";
export var preview = "Header-module--preview--9QyFs";
export var selected = "Header-module--selected--7gtc7";
export var upperNavigation = "Header-module--upperNavigation--AyA-P";
export var wrapper = "Header-module--wrapper--+Rccd";