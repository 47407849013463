import React from 'react'
import { configure } from 'mobx'
import { Location } from '@reach/router'
import { ThemeProvider } from 'emotion-theming'
import { I18nextProvider } from 'react-i18next'

import i18n from 'src/i18n/i18n'
import Ui from 'src/stores/ui/ui'
import Press from 'src/stores/press/press'
import { StoreTypes } from 'src/stores/types'

import { Props } from './types'

const theme = {
  breakpoints: ['374px', '767px', '1024px', '1099px', '1920px'],
  space: [...Array(60)].map((_, i) => i * 4),
}

export const stores: StoreTypes = {
  ui: new Ui(),
  press: new Press(),
}

configure({
  enforceActions: 'always', // enforces @action to update mobx states
})

export const StoresContext = React.createContext<StoreTypes>(stores)

export default ({ element }: Props) => {
  return (
    <StoresContext.Provider value={stores}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <Location>
            {({ location }) => {
              return element
            }}
          </Location>
        </I18nextProvider>
      </ThemeProvider>
    </StoresContext.Provider>
  )
}
